// src/pages/Students.js
import React from 'react';

function Students() {
  return (
    <div>
      <h1>Students Page</h1>
      <p>This is where you'll manage students.</p>
    </div>
  );
}

export default Students;
