// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import NavBar from './components/NavBar';
import Login from './components/Login';
import HomePage from './pages/HomePage';
import Dashboard from './pages/Dashboard';
import Schools from './pages/Schools';
import Recruits from './pages/Recruits';
import Territories from './pages/Territories';
import Visits from './pages/Visits';
import Students from './pages/Students';
import Applications from './pages/Applications';
import AddVisit from './pages/AddVisit';
import CalendarPage from './pages/CalendarPage';
import AddSchool from './pages/AddSchool';
import AddRecruiter from './pages/AddRecruiter';
import AddCounselor from './pages/AddCounselor';
import RecruiterMaintenance from './pages/RecruiterMaintenance';
import ProtectedRoute from './components/ProtectedRoute';
import { supabase } from './utils/supabaseClient';
import UserManagement from './pages/UserManagement';
import ApplicantsUpload from './pages/ApplicantsUpload';
import './styles/App.css';

function App() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      //console.log("Session fetched:", session);
      setSession(session);
      setLoading(false);
    };

    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        //console.log("Session updated:", session);
        setSession(session);
      }
    );

    return () => {
      authListener?.unsubscribe?.();
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="d-flex" style={{ minHeight: '100vh' }}>
        <NavBar session={session} /> 
        <div className="sidebar-wrapper" style={{ width: '200px', position: 'fixed', top: '20px', left: 0 }}> 
          {session && <Sidebar />} 
        </div>
        <div className="flex-grow-1 p-4" style={{ marginLeft: '200px', overflowY: 'auto' }}> 
          <div className="content-wrapper" style={{ paddingTop: '85px' }}> {/* Add paddingTop to the main content wrapper */}
            <Routes>
              <Route path="/" element={session ? <Navigate to="/dashboard" /> : <HomePage />} />
              <Route path="/login" element={<Login />} />

              {/* Pass the session to ProtectedRoute */}
              <Route element={<ProtectedRoute session={session} />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/schools" element={<Schools />} />
                <Route path="/recruits" element={<Recruits />} />
                <Route path="/territories" element={<Territories />} />
                <Route path="/visits" element={<Visits />} />
                <Route path="/students" element={<Students />} />
                <Route path="/applications" element={<Applications />} />
                <Route path="/add-visit" element={<AddVisit />} />
                <Route path="/calendar" element={<CalendarPage />} />
                <Route path="/add-school" element={<AddSchool />} />
                <Route path="/add-recruiter" element={<AddRecruiter />} />
                <Route path="/add-counselor" element={<AddCounselor />} />
                <Route path="/recruiter-maintenance" element={<RecruiterMaintenance />} />
                <Route path="/user-management" element={<UserManagement />} />
                <Route path="/applicants-upload" element={<ApplicantsUpload />} />
              </Route>
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;