// src/pages/AddRecruiter.js

import React, { useState } from 'react';
import { supabase } from '../utils/supabaseClient';

const AddRecruiter = () => {
  const [id, setId] = useState('');  // Banner ID
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [color, setColor] = useState('#006834');  // Default color

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { data, error } = await supabase
      .from('Recruiters')
      .insert([
        {
          id: id,  // Assign the Banner ID
          FirstName: firstName,
          LastName: lastName,
          Phone_Number: phoneNumber,
          Title: title,
          Email: email,
          Color: color,
        },
      ]);

    if (error) {
      console.error('Error adding recruiter:', error);
    } else {
      console.log('Recruiter added:', data);
      // Clear the form or redirect as needed
      setId('');  // Clear Banner ID
      setFirstName('');
      setLastName('');
      setPhoneNumber('');
      setTitle('');
      setEmail('');
      setColor('#006834');  // Reset to default color
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
      <div style={{ width: '30%' }}>
        <h1>Add New Recruiter</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="id">Banner ID</label>
            <input
              type="text"
              id="id"
              className="form-control"
              value={id}
              onChange={(e) => setId(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              className="form-control"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              className="form-control"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="tel"
              id="phoneNumber"
              className="form-control"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="color">Color</label>
            <input
              type="color"
              id="color"
              className="form-control"
              value={color}
              onChange={(e) => setColor(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Add Recruiter
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddRecruiter;
