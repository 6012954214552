// pages/HomePage.js

import React from 'react';
import { Link } from 'react-router-dom';

function HomePage() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-10">
          <div className="text-center mt-5">
            <h1 className="display-4 text-gray-800">Welcome to ABAC Recruit</h1>
            <p className="lead text-gray-600">Your tool for managing and tracking high school recruitment.</p>
            <hr className="my-4" />
            <p className="text-gray-600">Please login to access your dashboard and manage your activities.</p>
            <Link to="/login" className="btn btn-primary btn-lg">
              Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
