/* src/components/SchoolModal.js */

import React, { useState, useEffect } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { supabase } from '../utils/supabaseClient';
import { unparse } from 'papaparse';
import { saveAs } from 'file-saver';

function SchoolModal({ ceebCode, show, handleClose }) {
  const [school, setSchool] = useState(null);
  const [counselors, setCounselors] = useState([]);
  const [visits, setVisits] = useState([]);
  const [recruiter, setRecruiter] = useState(null);
  const [applicants, setApplicants] = useState([]);
  const [prospects, setProspects] = useState([]); // State for prospects
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (ceebCode && show) {
      const fetchSchoolDetails = async () => {
        try {
          const schoolPromise = supabase
            .from('HighSchools')
            .select('*')
            .eq('CEEB_Code', ceebCode)
            .single();

          const counselorsPromise = supabase
            .from('High_School_Counselors')
            .select('*')
            .eq('CEEB_Code', ceebCode);

          const visitsPromise = supabase
            .from('Visits')
            .select('*')
            .eq('CEEB_Code', ceebCode);

          const applicantsPromise = supabase
            .from('Applicants')
            .select('*') // Fetch all columns for the export
            .eq('HS_Code', ceebCode)
            .gt('HsGradDate', '2025-04-01') // Filter for Fall 2025 applicants
            .eq('Term', '202508');  // Fall 2025

          const prospectsPromise = supabase
            .from('Applicants')
            .select('*') // Fetch all columns for the export
            .eq('HS_Code', ceebCode)
            .gt('HsGradDate', '2025-04-01'); // Filter for Prospects

          const [schoolRes, counselorsRes, visitsRes, applicantsRes, prospectsRes] = await Promise.all([
            schoolPromise,
            counselorsPromise,
            visitsPromise,
            applicantsPromise,
            prospectsPromise,
          ]);

          if (schoolRes.error || counselorsRes.error || visitsRes.error || applicantsRes.error || prospectsRes.error) {
            throw new Error('Failed to fetch data');
          }

          const school = schoolRes.data;
          const counselorData = counselorsRes.data;
          const visitData = visitsRes.data;
          const applicantData = applicantsRes.data;
          const prospectData = prospectsRes.data; // Prospects data

          // Fetch recruiter information if available
          if (school.City && school.State) {
            const { data: countyData, error: countyError } = await supabase
              .from('County')
              .select('RecruiterID')
              .eq('State', school.State)
              .eq('County', school.County)
              .single();

            if (countyError) throw countyError;

            const { data: recruiterData, error: recruiterError } = await supabase
              .from('Recruiters')
              .select('FirstName, LastName')
              .eq('id', countyData.RecruiterID)
              .single();

            if (recruiterError) throw recruiterError;

            setRecruiter(recruiterData);
          }

          setSchool(school);
          setCounselors(counselorData);
          setVisits(visitData);
          setApplicants(applicantData);
          setProspects(prospectData); // Set prospects data
        } catch (error) {
          setError('Error fetching school details.');
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      fetchSchoolDetails();
    }
  }, [ceebCode, show]);

  const handleExportApplicants = () => {
    const csvData = unparse(applicants);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `fall2025_applicants_${ceebCode}.csv`);
  };

  const handleExportProspects = () => {
    const csvData = unparse(prospects);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `prospects_${ceebCode}.csv`);
  };

  if (loading) return null;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{school ? school.School_Name : 'School Details'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {school && (
          <>
            <p><strong>Address:</strong> {school.Address}, {school.City}, {school.State} {school.Zip}</p>
            <p><strong>Phone:</strong> {school.School_Phone}</p>
            <p><strong>District:</strong> {school.District}</p>
            <p><strong>County:</strong> {school.County}</p>
            <p><strong>Recruiter:</strong> {recruiter ? `${recruiter.FirstName} ${recruiter.LastName}` : 'Unassigned'}</p>
          </>
        )}
        <hr />
        <h5>High School Counselors</h5>
        {counselors.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {counselors.map((counselor) => (
                <tr key={counselor.Counselor_Email}>
                  <td>{counselor.FirstName}</td>
                  <td>{counselor.LastName}</td>
                  <td>{counselor.Counselor_Email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No counselors found.</p>
        )}
        <hr />
        <h5>Visit Records</h5>
        {visits.length > 0 ? (
          <ul>
            {visits.map((visit) => (
              <li key={visit.id}>
                {new Date(visit.StartDate).toLocaleDateString()} - {visit.Visit_Type}: {visit.Notes}
              </li>
            ))}
          </ul>
        ) : (
          <p>No visit records found.</p>
        )}
        <p><strong>Total Visits:</strong> {visits.length}</p>
        <hr />
        <h5>Fall 2025 Applicants</h5>
        {applicants.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Decision</th>
                <th>HS Graduation Date</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {applicants.map((applicant, index) => (
                <tr key={index}>
                  <td>{applicant.FirstName}</td>
                  <td>{applicant.LastName}</td>
                  <td>{applicant.Decision}</td>
                  <td>{new Date(applicant.HsGradDate).toLocaleDateString()}</td>
                  <td>{applicant.Email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No applicants found.</p>
        )}
        <Button variant="primary" onClick={handleExportApplicants} className="mt-3">
          Export Fall 2025 Applicants
        </Button>
        <Button variant="primary" onClick={handleExportProspects}className="mt-3">
          Export Prospects
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>

      </Modal.Footer>
    </Modal>
  );
}

export default SchoolModal;
