// File: src/pages/Visits.js

import React, { useState, useEffect, useMemo } from 'react';
import { supabase } from '../utils/supabaseClient';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import EventDetailsModal from '../components/EventDetailsModal';

function Visits() {
  const [visits, setVisits] = useState([]);
  const [filteredVisits, setFilteredVisits] = useState([]);
  const [schools, setSchools] = useState([]);
  const [recruiters, setRecruiters] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedRecruiter, setSelectedRecruiter] = useState(null);
  const [selectedVisit, setSelectedVisit] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateFilter, setDateFilter] = useState('all');

  const [currentPage, setCurrentPage] = useState(1);
  const visitsPerPage = 25;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchVisits = async () => {
      try {
        const { data: visitsData, error: visitsError } = await supabase
          .from('Visits')
          .select(`
            id,
            CEEB_Code,
            RecruiterID,
            StartDate,
            EndDate,
            Notes,
            Visit_Type,
            Start_Time,
            End_Time,
            Location,
            Address1,
            Address2,
            City,
            State,
            Host_FullName,
            Host_Email,
            Host_Phone,
            Zipcode,
            Handouts,
            Event_Name,
            FileAttachments
          `)
          .order('StartDate', { ascending: true });

        if (visitsError) {
          console.error('Error fetching visits:', visitsError);
          return;
        }

        const schoolIds = [...new Set(visitsData.map(visit => visit.CEEB_Code))];
        const recruiterIds = [...new Set(visitsData.map(visit => visit.RecruiterID))].filter(id => id !== null);

        const { data: schoolsData, error: schoolsError } = await supabase
          .from('HighSchools')
          .select('CEEB_Code, School_Name')
          .in('CEEB_Code', schoolIds);

        if (schoolsError) {
          console.error('Error fetching schools:', schoolsError);
        }

        const { data: recruitersData, error: recruitersError } = await supabase
          .from('Recruiters')
          .select('id, FirstName, LastName, Color')
          .in('id', recruiterIds);

        if (recruitersError) {
          console.error('Error fetching recruiters:', recruitersError);
        }

        const schoolMap = {};
        schoolsData.forEach(school => {
          schoolMap[school.CEEB_Code] = school.School_Name;
        });

        const recruiterMap = {};
        recruitersData.forEach(recruiter => {
          recruiterMap[recruiter.id] = {
            firstName: recruiter.FirstName,
            lastName: recruiter.LastName,
            color: recruiter.Color || '#006834',
          };
        });

        const visitsWithDetails = visitsData.map(visit => {
          const schoolName = schoolMap[visit.CEEB_Code] || 'N/A';
          const recruiter = recruiterMap[visit.RecruiterID] || { color: '#ccc', firstName: '', lastName: '' };
          
          const startDateTime = new Date(`${visit.StartDate}T${visit.Start_Time}`);
          const endDateTime = visit.EndDate
            ? new Date(`${visit.EndDate}T${visit.End_Time}`)
            : new Date(`${visit.StartDate}T${visit.End_Time}`);
          
          const formattedStartDate = moment(visit.StartDate).format('MM/DD/YYYY');

          return {
            ...visit,
            title: `${recruiter.firstName} - ${visit.Event_Name}`,
            start: startDateTime,
            end: endDateTime,
            recruiter: recruiter,
            schoolName: schoolName, // Include school name
            formattedStartDate, // Include formatted date for display
          };
        });

        setVisits(visitsWithDetails);
        setFilteredVisits(visitsWithDetails);
      } catch (error) {
        console.error('Error fetching visits:', error);
      }
    };

    const fetchSchools = async () => {
      try {
        const { data, error } = await supabase
          .from('HighSchools')
          .select('CEEB_Code, School_Name');
        if (error) {
          console.error('Error fetching schools:', error);
          return;
        }
        const schoolOptions = data.map(school => ({
          value: school['CEEB_Code'],
          label: school['School_Name'],
        })).sort((a, b) => a.label.localeCompare(b.label));
        setSchools(schoolOptions);
      } catch (error) {
        console.error('Error fetching schools:', error);
      }
    };

    const fetchRecruiters = async () => {
      try {
        const { data, error } = await supabase
          .from('Recruiters')
          .select('id, FirstName, LastName');
        if (error) {
          console.error('Error fetching recruiters:', error);
          return;
        }
        const recruiterOptions = data.map(recruiter => ({
          value: recruiter['id'],
          label: `${recruiter['FirstName']} ${recruiter['LastName']}`,
        })).sort((a, b) => a.label.localeCompare(b.label));
        setRecruiters(recruiterOptions);
      } catch (error) {
        console.error('Error fetching recruiters:', error);
      }
    };

    fetchVisits();
    fetchSchools();
    fetchRecruiters();
  }, []);

  const filteredVisitsMemo = useMemo(() => {
    let filtered = visits;

    if (selectedSchool) {
      filtered = filtered.filter(visit => visit.CEEB_Code === selectedSchool.value);
    }

    if (selectedRecruiter) {
      filtered = filtered.filter(visit => visit.RecruiterID === selectedRecruiter.value);
    }

    const today = Date.now();

    if (dateFilter === 'past') {
      filtered = filtered.filter(visit => new Date(visit.start).getTime() < today);
    } else if (dateFilter === 'future') {
      filtered = filtered.filter(visit => new Date(visit.start).getTime() >= today);
    }

    if (startDate && endDate) {
      const startDateTime = new Date(startDate).getTime();
      const endDateTime = new Date(endDate).getTime();
      filtered = filtered.filter(visit => {
        const visitDateTime = new Date(visit.start).getTime();
        return visitDateTime >= startDateTime && visitDateTime <= endDateTime;
      });
    }

    filtered.sort((a, b) => new Date(a.start) - new Date(b.start));

    return filtered;
  }, [visits, selectedSchool, selectedRecruiter, dateFilter, startDate, endDate]);

  useEffect(() => {
    setFilteredVisits(filteredVisitsMemo);
    setCurrentPage(1); // Reset to the first page when the filtered visits change
  }, [filteredVisitsMemo]);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this visit?')) {
      try {
        const { error } = await supabase
          .from('Visits')
          .delete()
          .eq('id', id);

        if (error) {
          console.error('Error deleting visit:', error);
        } else {
          setFilteredVisits(filteredVisits.filter(visit => visit.id !== id));
          setVisits(visits.filter(visit => visit.id !== id));
        }
      } catch (error) {
        console.error('Error deleting visit:', error);
      }
    }
  };

  const handleViewClick = (visit) => {
    setSelectedVisit(visit); // Set the current visit to view
    setShowModal(true); // Show the modal
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedVisit(null);
  };

  const handleUpdateVisit = (updatedVisit) => {
    const visitIndex = visits.findIndex(v => v.id === updatedVisit.id);
    if (visitIndex !== -1) {
      const newVisits = [...visits];
      newVisits[visitIndex] = updatedVisit;
      setVisits(newVisits);
      setFilteredVisits(newVisits);
    }
  };

  const handleAddVisitClick = () => {
    navigate('/add-visit');
  };

  const formatTime = (time) => {
    if (!time) return 'N/A';
    return moment(`1970-01-01T${time}`).format('hh:mm A');
  };

  const getRowColor = (visit) => {
    const today = moment().startOf('day'); // Start of today to ignore time
    const visitDate = moment(visit.start); // Use the start date directly as a moment object
  
    return visitDate.isBefore(today) ? '#f8d7da' : '#d4edda'; // Red for past, green for future
  };

  // Pagination logic
  const indexOfLastVisit = currentPage * visitsPerPage;
  const indexOfFirstVisit = indexOfLastVisit - visitsPerPage;
  const currentVisits = filteredVisits.slice(indexOfFirstVisit, indexOfLastVisit);

  const totalPages = Math.ceil(filteredVisits.length / visitsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="container-fluid">
      <button className="btn btn-primary mb-4" onClick={handleAddVisitClick}>
        Add Visit
      </button>

      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Filter Visits</h6>
        </div>
        <div className="card-body">
          <div className="form-row mb-3">
            <div className="col-md-4">
              <Select
                options={schools}
                value={selectedSchool}
                onChange={(option) => setSelectedSchool(option)}
                placeholder="Filter by School"
                isClearable
              />
            </div>
            <div className="col-md-4">
              <Select
                options={recruiters}
                value={selectedRecruiter}
                onChange={(option) => setSelectedRecruiter(option)}
                placeholder="Filter by Recruiter"
                isClearable
              />
            </div>
            <div className="col-md-4">
              <select
                className="form-control"
                value={dateFilter}
                onChange={(e) => setDateFilter(e.target.value)}
              >
                <option value="all">All Dates</option>
                <option value="past">Past Dates</option>
                <option value="future">Future Dates</option>
              </select>
            </div>
          </div>
          <div className="form-row mb-3">
            <div className="col-md-6">
              <label>Start Date:</label>
              <input
                type="date"
                className="form-control"
                value={startDate || ''}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="col-md-6">
              <label>End Date:</label>
              <input
                type="date"
                className="form-control"
                value={endDate || ''}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Visit Records ({filteredVisits.length})</h6>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-bordered" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th>Event Name</th>
                  <th>Location</th>
                  <th>School Name</th>
                  <th>Recruiter Name</th>
                  <th>Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentVisits.map((visit) => (
                  <tr key={visit.id} style={{ backgroundColor: getRowColor(visit) }}>
                    <td>{visit.Event_Name}</td>
                    <td>{visit.location}</td>
                    <td>{visit.schoolName}</td>
                    <td>{visit.recruiter.firstName} {visit.recruiter.lastName}</td>
                    <td>{visit.formattedStartDate}</td>
                    <td>{formatTime(visit.Start_Time)}</td>
                    <td>{formatTime(visit.End_Time)}</td>
                    <td>
                      <button
                        className="btn btn-info btn-sm"
                        onClick={() => handleViewClick(visit)}
                      >
                        View
                      </button>
                      <button className="btn btn-danger btn-sm" onClick={() => handleDelete(visit.id)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination controls */}
          <nav>
            <ul className="pagination justify-content-center">
              {Array.from({ length: totalPages }, (_, index) => (
                <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                  <button onClick={() => paginate(index + 1)} className="page-link">
                    {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      {showModal && (
        <EventDetailsModal
          event={selectedVisit}
          onClose={handleModalClose}
          onUpdateEvent={handleUpdateVisit}
        />
      )}
    </div>
  );
}

export default Visits;
