import React, { useState } from 'react';

const ApplicantsUpload = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [uploadedFilePath, setUploadedFilePath] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      setUploadStatus('Please select a file to upload.');
      return;
    }

    setUploadStatus('Uploading...');

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('http://localhost:5000/upload', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.error || 'Upload failed');
      }

      const result = await response.json();
      setUploadStatus('Upload successful!');
      setUploadedFilePath(result.path);
    } catch (error) {
      setUploadStatus(`Upload failed: ${error.message}`);
    }
  };

  return (
    <div className="container">
      <h1>Applicants Upload</h1>
      <p>Select a file to upload:</p>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleFileUpload}>Upload</button>
      {uploadStatus && <p>{uploadStatus}</p>}
      {uploadedFilePath && (
        <p>
          File uploaded to: <a href={uploadedFilePath} target="_blank" rel="noopener noreferrer">{uploadedFilePath}</a>
        </p>
      )}
    </div>
  );
};

export default ApplicantsUpload;
