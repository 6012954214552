import React, { useReducer, useEffect, useState, useMemo } from 'react';
import Select from 'react-select';
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
import CounselorsList from './CounselorsList';
import SchoolModal from '../components/SchoolModal'; // Import the SchoolModal component
import { supabase } from '../utils/supabaseClient';

function useFetchData() {
  const initialState = {
    schools: [],
    counselors: [],
    recruiters: [],
    counties: [],
    priorities: [],
    loading: true,
    error: null,
    counselorError: null,
    counselorLoading: false,
    currentPage: 1,
    schoolsPerPage: 30, // Adjust as needed
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'FETCH_SUCCESS':
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case 'FETCH_ERROR':
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
      case 'SET_COUNSELORS':
        return {
          ...state,
          counselors: action.payload,
          counselorLoading: false,
        };
      case 'SET_COUNSELORS_ERROR':
        return {
          ...state,
          counselorError: action.payload,
          counselorLoading: false,
        };
      case 'SET_COUNSELORS_LOADING':
        return {
          ...state,
          counselorLoading: true,
        };
      case 'SET_CURRENT_PAGE':
        return { ...state, currentPage: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const recruitersRes = await supabase.from('Recruiters').select('id, FirstName, LastName');
        const countiesRes = await supabase.from('County').select('State, County, Priority, RecruiterID');
        // Pagination for schools
        let schoolsData = [];
        let from = 0;
        const pageSize = 1000;
        let hasMore = true;
        while (hasMore) {
          const { data, error } = await supabase
            .from('HighSchools')
            .select('*')
            .range(from, from + pageSize - 1);
          if (error) {
            throw new Error('Failed to fetch schools data');
          }
          schoolsData = [...schoolsData, ...data];
          from += pageSize;
          hasMore = data.length === pageSize;
        }

        const visitsRes = await supabase.from('Visits').select('*');
        // Pagination for applicants 2024
        let applicants2024Data = [];
        from = 0;
        hasMore = true;
        while (hasMore) {
          const { data, error } = await supabase.from('Applicants').select('*').eq('Term', '202408').range(from, from + pageSize - 1);
          if (error) {
            throw new Error('Failed to fetch applicants 2024 data');
          }
          applicants2024Data = [...applicants2024Data, ...data];
          from += pageSize;
          hasMore = data.length === pageSize;
        }

        // Pagination for applicants 2025
        let applicants2025Data = [];
        from = 0;
        hasMore = true;
        while (hasMore) {
          const { data, error } = await supabase.from('Applicants').select('*').eq('Term', '202508').range(from, from + pageSize - 1);
          if (error) {
            throw new Error('Failed to fetch applicants 2025 data');
          }
          applicants2025Data = [...applicants2025Data, ...data];
          from += pageSize;
          hasMore = data.length === pageSize;
        }


        if (recruitersRes.error || countiesRes.error || visitsRes.error || applicants2024Data.length === 0 || applicants2025Data.length === 0) {
          throw new Error('Failed to fetch some data');
        }

        const recruitersData = recruitersRes.data.sort((a, b) => {
          return a.LastName.toLowerCase().localeCompare(b.LastName.toLowerCase());
        });

        const priorityOrder = ['Primary', 'Secondary', 'Tertiary', 'Quaternary'];

        const countiesData = countiesRes.data.sort((a, b) => {
          return a.State === b.State ? a.County.localeCompare(b.County) : a.State.localeCompare(b.State);
        });

        const priorities = [...new Set(countiesData.map(c => c.Priority))].sort((a, b) => priorityOrder.indexOf(a) - priorityOrder.indexOf(b));

        const visitsData = visitsRes.data;

        const combinedData = schoolsData.map((school) => {
          const countyMatch = countiesData.find(c => c.State === school.State && c.County === school.County) || {};
          const recruiter = recruitersData.find(r => r.id === countyMatch.RecruiterID) || {};

          // Count applications for Fall 2024 and Fall 2025
          const fall2024Apps = applicants2024Data.filter(applicant => applicant.HS_Code === school.CEEB_Code).length;
          const fall2025Apps = applicants2025Data.filter(applicant => applicant.HS_Code === school.CEEB_Code).length;

          // Calculate percentage difference
          const percentageDifference = fall2024Apps === 0 ? 'N/A' : (((fall2025Apps - fall2024Apps) / fall2024Apps) * 100).toFixed(2) + '%';

          return {
            ...school,
            LastVisit: visitsData.find(v => v.CEEB_Code === school.CEEB_Code)?.StartDate ?
              new Date(visitsData.find(v => v.CEEB_Code === school.CEEB_Code).StartDate) :
              null,
            RecruiterName: `${recruiter.FirstName || ''} ${recruiter.LastName || ''}`.trim(),
            Priority: countyMatch.Priority || 'N/A',
            VisitCount: visitsData.filter(v => v.CEEB_Code === school.CEEB_Code).length,
            RecruiterID: countyMatch.RecruiterID,
            Fall2024Apps: fall2024Apps,
            Fall2025Apps: fall2025Apps,
            PercentageDifference: percentageDifference,
          };
        });

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: {
            schools: combinedData,
            recruiters: recruitersData,
            counties: countiesData,
            priorities: priorities,
          },
        });
      } catch (error) {
        dispatch({ type: 'FETCH_ERROR', payload: error.message });
      }
    };

    fetchData();
  }, []);

  return [state, dispatch];
}

function Schools() {
  const [state, dispatch] = useFetchData();
  const { schools, counselors, recruiters, counties, priorities, loading, error, counselorError, counselorLoading, currentPage, schoolsPerPage } = state;

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCounty, setSelectedCounty] = useState('');
  const [selectedRecruiter, setSelectedRecruiter] = useState('');
  const [selectedPriority, setSelectedPriority] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'School_Name', direction: 'asc' });
  const [countiesByState, setCountiesByState] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null); // State for the selected school in the modal
  const [showModal, setShowModal] = useState(false); // State for showing/hiding the modal

  const clearFilters = () => {
    setSearchTerm('');
    setSelectedState('');
    setSelectedCounty('');
    setSelectedRecruiter('');
    setSelectedPriority('');
    setStartDate('');
    setEndDate('');
    dispatch({ type: 'SET_CURRENT_PAGE', payload: 1 }); // Reset page to 1 on clear filters
  };


  const filterSchools = useMemo(() => {
    let filtered = schools;

    if (searchTerm) {
      filtered = filtered.filter(school => school['School_Name'].toLowerCase().includes(searchTerm.toLowerCase()));
    }
    if (selectedState) {
      filtered = filtered.filter(school => school.State === selectedState);
    }
    if (selectedCounty) {
      filtered = filtered.filter(school => school.County === selectedCounty);
    }
    if (selectedRecruiter) {
      filtered = filtered.filter(school => school.RecruiterID === selectedRecruiter);
    }
    if (selectedPriority) {
      filtered = filtered.filter(school => school.Priority === selectedPriority);
    }
    if (startDate && endDate) {
      filtered = filtered.filter(school => {
        const visitDate = school.LastVisit;
        return visitDate && visitDate >= new Date(startDate) && visitDate <= new Date(endDate);
      });
    }
    if (sortConfig) {
      filtered.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (aValue === null || aValue === undefined) aValue = '';
        if (bValue === null || bValue === undefined) bValue = '';

        if (aValue instanceof Date) {
          aValue = aValue.getTime();
        }

        if (bValue instanceof Date) {
          bValue = bValue.getTime();
        }

        return sortConfig.direction === 'asc' ? (aValue > bValue ? 1 : -1) : (aValue < bValue ? 1 : -1);
      });
    }

    return filtered;
  }, [schools, searchTerm, selectedState, selectedCounty, selectedRecruiter, selectedPriority, startDate, endDate, sortConfig]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleFetchCounselors = async () => {
    const CEEB_Codes = filterSchools.map(school => school.CEEB_Code);

    if (CEEB_Codes.length === 0) return;

    try {
      dispatch({ type: 'SET_COUNSELORS_LOADING' });
      const { data, error } = await supabase
        .from('High_School_Counselors')
        .select('Counselor_Email, FirstName, LastName, CEEB_Code')
        .in('CEEB_Code', CEEB_Codes);

      if (error) throw error;

      dispatch({ type: 'SET_COUNSELORS', payload: data.sort((a, b) => a['LastName'].localeCompare(b['LastName'])) });
    } catch (err) {
      dispatch({ type: 'SET_COUNSELORS_ERROR', payload: err.message });
    }
  };

  const handleExportSchools = () => {
    const csvData = unparse(filterSchools);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'schools.csv');
  };

  const handleExportCounselors = () => {
    const csvData = unparse(counselors);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'counselors.csv');
  };

  const handleStateSelect = (option) => {
    setSelectedState(option ? option.value : '');
    setSelectedCounty('');
    setCountiesByState(counties.filter(c => c.State === option.value));
  };

  const handleCountySelect = (option) => {
    setSelectedCounty(option ? option.value : '');
  };

  const handleRecruiterSelect = (option) => {
    setSelectedRecruiter(option ? option.value : '');
  };

  const handlePrioritySelect = (option) => {
    setSelectedPriority(option ? option.value : '');
  };

  const handleSchoolClick = (ceebCode) => {
    setSelectedSchool(ceebCode);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSchool(null);
  };

  const indexOfLastSchool = currentPage * schoolsPerPage;
  const indexOfFirstSchool = indexOfLastSchool - schoolsPerPage;
  const currentSchools = filterSchools.slice(indexOfFirstSchool, indexOfLastSchool);

  const paginate = (pageNumber) => dispatch({ type: 'SET_CURRENT_PAGE', payload: pageNumber });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">School Management</h1>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Filter Schools</h6>
        </div>
        <div className="card-body">
          {/* Search Bar */}
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search by School Name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          {/* Filters */}
          <div className="form-row">
            <div className="form-group col-md-3">
              <Select
                options={[...new Set(schools.map(s => s.State))].sort((a, b) => a.localeCompare(b)).map(s => ({ value: s, label: s }))}
                value={selectedState ? { value: selectedState, label: selectedState } : null}
                onChange={handleStateSelect}
                placeholder="Filter by State"
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '25px',
                    borderColor: '#ced4da',
                  }),
                }}
              />
            </div>

            <div className="form-group col-md-3">
              <Select
                options={countiesByState.map(c => ({ value: c.County, label: c.County }))}
                value={selectedCounty ? { value: selectedCounty, label: selectedCounty } : null}
                onChange={handleCountySelect}
                placeholder="Filter by County"
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '25px',
                    borderColor: '#ced4da',
                  }),
                }}
              />
            </div>

            <div className="form-group col-md-3">
              <Select
                options={recruiters.map(r => ({ value: r.id, label: `${r.FirstName} ${r.LastName}` }))}
                value={selectedRecruiter
                  ? { value: selectedRecruiter, label: `${recruiters.find(recruiter => recruiter.id === selectedRecruiter)?.FirstName || ''} ${recruiters.find(recruiter => recruiter.id === selectedRecruiter)?.LastName || ''}` }
                  : null}
                onChange={handleRecruiterSelect}
                placeholder="Filter by Recruiter"
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '25px',
                    borderColor: '#ced4da',
                  }),
                }}
              />
            </div>

            <div className="form-group col-md-3">
              <Select
                options={priorities.map(p => ({ value: p, label: p }))}
                value={selectedPriority ? { value: selectedPriority, label: selectedPriority } : null}
                onChange={handlePrioritySelect}
                placeholder="Filter by Priority"
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '25px',
                    borderColor: '#ced4da',
                  }),
                }}
              />
            </div>
          </div>

          {/* Date Range Filter */}
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Visit Start Date:</label>
              <input type="date" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </div>
            <div className="form-group col-md-6">
              <label>Visit End Date:</label>
              <input type="date" className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            </div>
          </div>
          <button className="btn btn-secondary" onClick={clearFilters}>Clear Filters</button>
        </div>
      </div>

      {/* Render filtered schools */}
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Schools</h6>
        </div>
        <div className="card-body">
          {filterSchools.length === 0 ? (
            <div>No schools found</div>
          ) : (
            <div className="table-responsive">
              <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                <thead>
                  <tr>
                    <th onClick={() => requestSort('School_Name')}>School Name</th>
                    <th onClick={() => requestSort('LastVisit')}>Last Visit</th>
                    <th onClick={() => requestSort('RecruiterName')}>Recruiter</th>
                    <th onClick={() => requestSort('Priority')}>Priority</th>
                    <th onClick={() => requestSort('VisitCount')}>Visit Count</th>
                    <th onClick={() => requestSort('Fall2024Apps')}>Fall 2024 Apps</th>
                    <th onClick={() => requestSort('Fall2025Apps')}>Fall 2025 Apps</th>
                    <th onClick={() => requestSort('PercentageDifference')}>% Difference</th>
                  </tr>
                </thead>
                <tbody>
                  {currentSchools.map((school) => (
                    <tr key={school.CEEB_Code}>
                      <td>
                        <span
                          className="school-name-clickable"
                          onClick={() => handleSchoolClick(school.CEEB_Code)}
                          style={{ fontWeight: '600', cursor: 'pointer', color: '#006834' }}
                        >
                          {school.School_Name}
                        </span>
                      </td>
                      <td>{school.LastVisit ? school.LastVisit.toLocaleDateString() : 'N/A'}</td>
                      <td>{school.RecruiterName || 'N/A'}</td>
                      <td>{school.Priority}</td>
                      <td>{school.VisitCount}</td>
                      <td>{school.Fall2024Apps}</td>
                      <td>{school.Fall2025Apps}</td>
                      <td>{school.PercentageDifference}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  {Array.from({ length: Math.ceil(filterSchools.length / schoolsPerPage) }).map((_, index) => (
                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                      <button className="page-link" onClick={() => paginate(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>

      {/* View Counselors Button */}
      {filterSchools.length > 0 && (
        <button className="btn btn-primary" onClick={handleFetchCounselors}>View Counselors for Listed Schools</button>
      )}

      {/* Display counselors */}
      <CounselorsList
        counselors={counselors}
        counselorError={counselorError}
        counselorLoading={counselorLoading}
      />

      {/* Export Buttons */}
      <div className="mt-4">
        <button className="btn btn-success mr-2" onClick={handleExportSchools}>Export Schools</button>
        <button className="btn btn-success" onClick={handleExportCounselors}>Export Counselors</button>
      </div>

      {/* School Details Modal */}
      {selectedSchool && (
        <SchoolModal
          ceebCode={selectedSchool}
          show={showModal}
          handleClose={handleCloseModal}
        />
      )}
    </div>
  );
}

export default Schools;