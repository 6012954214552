// /pages/Dashboard.js

import React, { useEffect, useState } from 'react';
import { supabase } from '../utils/supabaseClient';
import { format, parseISO } from 'date-fns';

function Dashboard() {
  const [session, setSession] = useState(null);
  const [recruiter, setRecruiter] = useState(null);
  const [upcomingVisits, setUpcomingVisits] = useState([]);

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);

      if (session) {
        try {
          // Fetch recruiter data based on the authenticated user's email
          const { data: recruiterData, error: recruiterError } = await supabase
            .from('Recruiters')
            .select('*')
            .ilike('Email', session.user.email)
            .single();

          if (recruiterError) {
            console.error('Error fetching recruiter:', recruiterError.message);
            return;
          }
          setRecruiter(recruiterData);

          // Fetch upcoming visits
          const { data: visitsData, error: visitsError } = await supabase
            .from('Visits')
            .select('*')
            .eq('RecruiterID', recruiterData.id)
            .gt('StartDate', new Date().toISOString())
            .order('StartDate', { ascending: true })
            .limit(6);

          if (visitsError) {
            console.error('Error fetching visits:', visitsError.message);
            return;
          }
          setUpcomingVisits(visitsData);
        } catch (error) {
          console.error('Unexpected error during data fetching:', error.message, error);
        }
      }
    };

    fetchSession();
  }, []);

  if (!session) {
    return <div>Please log in to access the dashboard.</div>;
  }

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">Dashboard</h1>

      <div className="row">

        {/* Welcome Card */}
        <div className="col-xl-4 col-lg-4 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Welcome
                  </div>
                  <div className="h6 mb-0 font-weight-bold text-gray-800">
                    {recruiter ? recruiter.FirstName : 'Recruiter'}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-user fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Upcoming Visits */}
        <div className="col-xl-8 col-lg-8 mb-4">
          <div className="card shadow mb-4">
            <div className="card-header py-3 d-flex align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary">Upcoming Visits</h6>
            </div>
            <div className="card-body">
              <ul className="list-group">
                {upcomingVisits.length > 0 ? (
                  upcomingVisits.map(visit => (
                    <li className="list-group-item mb-3 p-3" key={visit.id}>
                      <div className="d-flex justify-content-between">
                        <div>
                          <strong>{visit.Event_Name || 'Visit'}:</strong> {format(parseISO(visit.StartDate), 'MMMM do, yyyy')} at {visit.Location}
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="list-group-item">No upcoming visits.</li>
                )}
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Dashboard;
