import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        navigate('/dashboard'); // Redirect if already logged in
      }
    };

    checkSession();
  }, [navigate]); // Only run on mount

  const handleLogin = async () => {
    if (!email.endsWith('@abac.edu')) {
      setErrorMessage('Only ABAC email addresses are allowed.');
      return;
    }

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        setErrorMessage(error.message);
        console.error('Login error:', error.message);
      } else {
        console.log('User logged in:', data.user);
        setErrorMessage('');
        navigate('/dashboard'); // Navigate to dashboard on success
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrorMessage('An error occurred during login. Please try again.');
    }
  };

  return (
    <div className="card shadow-sm rounded" style={{ maxWidth: '400px', margin: '50px auto' }}>
      <div className="card-body">
        <h5 className="card-title text-center mb-4">Login</h5>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {errorMessage && <p className="text-danger mt-2">{errorMessage}</p>}
        <button type="button" className="btn btn-primary btn-block mt-4" onClick={handleLogin}>
          Login
        </button>
      </div>
    </div>
  );
}

export default Login;
