import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { supabase } from '../utils/supabaseClient';
import { useNavigate } from 'react-router-dom';

function AddVisit() {
  const [schools, setSchools] = useState([]);
  const [recruiters, setRecruiters] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [recruiterId, setRecruiterId] = useState('');
  const [dateVisited, setDateVisited] = useState('');
  const [visitType, setVisitType] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [location, setLocation] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [hostFullName, setHostFullName] = useState('');
  const [hostEmail, setHostEmail] = useState('');
  const [hostPhone, setHostPhone] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSchools = async () => {
      const { data, error } = await supabase
        .from('HighSchools')
        .select('CEEB_Code, School_Name');
      if (error) {
        console.error('Error fetching schools:', error);
      } else {
        const schoolOptions = data.map(school => ({
          value: school['CEEB_Code'],
          label: school['School_Name'],
        })).sort((a, b) => a.label.localeCompare(b.label));
        setSchools(schoolOptions);
      }
    };

    const fetchRecruiters = async () => {
      const { data, error } = await supabase
        .from('Recruiters')
        .select('id, FirstName, LastName');
      if (error) {
        console.error('Error fetching recruiters:', error);
      } else {
        setRecruiters(data.sort((a, b) => a['FirstName'].localeCompare(b['FirstName'])));
      }
    };

    fetchSchools();
    fetchRecruiters();
  }, []);

  const formatTime = (time) => {
    return new Date(`1970-01-01T${time}:00`).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'America/New_York',
    });
  };

  const generateICSFile = () => {
    const startDateTime = new Date(`${dateVisited}T${startTime}:00`).toISOString().replace(/[-:]/g, '').split('.')[0];
    const endDateTime = new Date(`${dateVisited}T${endTime}:00`).toISOString().replace(/[-:]/g, '').split('.')[0];
    const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${startDateTime}Z
DTEND:${endDateTime}Z
SUMMARY:${visitType} Visit
DESCRIPTION:Visit with details
LOCATION:${location}, ${address1} ${address2}, ${city}, ${state}
END:VEVENT
END:VCALENDAR
    `;
    const blob = new Blob([icsContent.trim()], { type: 'text/calendar' });
    return URL.createObjectURL(blob);
  };

  const handleSubmit = async (e, shouldEmail = false) => {
    e.preventDefault();

    const { error } = await supabase
      .from('Visits')
      .insert([
        {
          CEEB_Code: selectedSchool?.value,
          RecruiterID: recruiterId,
          StartDate: dateVisited,
          Visit_Type: visitType,
          Start_Time: startTime,
          End_Time: endTime,
          Location: location,
          Address1: address1,
          Address2: address2,
          City: city,
          State: state,
          Host_FullName: hostFullName,
          Host_Email: hostEmail,
          Host_Phone: hostPhone,
        },
      ]);

    if (error) {
      console.error('Error adding visit:', error);
    } else {
      alert('Visit added successfully!');

      if (shouldEmail) {
        const emailBody = `
          Visit Details:\n\n
          School Name: ${selectedSchool?.label || 'N/A'}\n
          Date Visited: ${new Date(dateVisited).toLocaleDateString()}\n
          Visit Type: ${visitType}\n
          Start Time: ${formatTime(startTime)}\n
          End Time: ${formatTime(endTime)}\n
          Location: ${location}\n
          Address: ${address1 || ''} ${address2 || ''}, ${city}, ${state}\n
          Host Name: ${hostFullName || 'N/A'}\n
          Host Email: ${hostEmail || 'N/A'}\n
          Host Phone: ${hostPhone || 'N/A'}
        `;

        const icsFileUrl = generateICSFile();

        window.location.href = `mailto:${recruiters.find(r => r.id === recruiterId)?.FirstName || ''} ${recruiters.find(r => r.id === recruiterId)?.LastName || ''}?subject=Visit Details&body=${encodeURIComponent(emailBody)}&attachment="${icsFileUrl}"`;
      }

      navigate('/visits');
    }
  };

  return (
    <div className="container">
      <h1 className="h3 mb-4 text-gray-800">Add Visit</h1>
      <form onSubmit={(e) => handleSubmit(e, false)} className="card shadow mb-4 p-4">
        <div className="form-group">
          <label>School:</label>
          <Select
            options={schools}
            value={selectedSchool}
            onChange={(option) => setSelectedSchool(option)}
            placeholder="Search for a school..."
            isClearable
            className="mb-3"
          />
        </div>
        <div className="form-group">
          <label>Recruiter:</label>
          <select
            value={recruiterId}
            onChange={(e) => setRecruiterId(e.target.value)}
            className="form-control mb-3"
            required
          >
            <option value="">Select Recruiter</option>
            {recruiters.map(recruiter => (
              <option key={recruiter['id']} value={recruiter['id']}>
                {recruiter['FirstName']} {recruiter['LastName']}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Date Visited:</label>
          <input
            type="date"
            value={dateVisited}
            onChange={(e) => setDateVisited(e.target.value)}
            className="form-control mb-3"
            required
          />
        </div>
        <div className="form-group">
          <label>Start Time:</label>
          <input
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            className="form-control mb-3"
          />
        </div>
        <div className="form-group">
          <label>End Time:</label>
          <input
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            className="form-control mb-3"
          />
        </div>
        <div className="form-group">
          <label>Visit Type:</label>
          <input
            type="text"
            value={visitType}
            onChange={(e) => setVisitType(e.target.value)}
            className="form-control mb-3"
            required
          />
        </div>
        <div className="form-group">
          <label>Location:</label>
          <input
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="form-control mb-3"
          />
        </div>
        <div className="form-group">
          <label>Address 1:</label>
          <input
            type="text"
            value={address1}
            onChange={(e) => setAddress1(e.target.value)}
            className="form-control mb-3"
          />
        </div>
        <div className="form-group">
          <label>Address 2:</label>
          <input
            type="text"
            value={address2}
            onChange={(e) => setAddress2(e.target.value)}
            className="form-control mb-3"
          />
        </div>
        <div className="form-group">
          <label>City:</label>
          <input
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            className="form-control mb-3"
          />
        </div>
        <div className="form-group">
          <label>State:</label>
          <input
            type="text"
            value={state}
            onChange={(e) => setState(e.target.value)}
            className="form-control mb-3"
          />
        </div>
        <div className="form-group">
          <label>Host Full Name:</label>
          <input
            type="text"
            value={hostFullName}
            onChange={(e) => setHostFullName(e.target.value)}
            className="form-control mb-3"
          />
        </div>
        <div className="form-group">
          <label>Host Email:</label>
          <input
            type="email"
            value={hostEmail}
            onChange={(e) => setHostEmail(e.target.value)}
            className="form-control mb-3"
          />
        </div>
        <div className="form-group">
          <label>Host Phone:</label>
          <input
            type="text"
            value={hostPhone}
            onChange={(e) => setHostPhone(e.target.value)}
            className="form-control mb-3"
          />
        </div>
        <button type="submit" className="btn btn-primary mr-2">Save</button>
        <button type="button" className="btn btn-secondary" onClick={(e) => handleSubmit(e, true)}>Save and Email</button>
      </form>
    </div>
  );
}

export default AddVisit;
