import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { supabase } from '../utils/supabaseClient';
import EventDetailsModal from '../components/EventDetailsModal';
import Select from 'react-select'; 
import '../styles/CustomCalendar.css'; 

const localizer = momentLocalizer(moment);

const customStyles = {
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'white', 
  }),
  control: (provided) => ({
    ...provided,
    position: 'relative', // Important!
  }),
};

function CalendarPage() {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [recruiters, setRecruiters] = useState([]);
  const [selectedRecruiter, setSelectedRecruiter] = useState(null);

  useEffect(() => {
    const fetchVisits = async () => {
      const { data: visitsData, error: visitsError } = await supabase
        .from('Visits')
        .select('id, StartDate, EndDate, Start_Time, End_Time, Visit_Type, Location, CEEB_Code, RecruiterID, Notes, Address1, Address2, City, State, Host_FullName, Host_Email, Host_Phone, Zipcode, Handouts, Event_Name, FileAttachments');

      if (visitsError) {
        console.error('Error fetching visits:', visitsError);
        return;
      }

      const { data: recruitersData, error: recruitersError } = await supabase
        .from('Recruiters')
        .select('id, FirstName, LastName, Color');

      if (recruitersError) {
        console.error('Error fetching recruiters:', recruitersError);
        return;
      }

      const recruiterMap = {};
      recruitersData.forEach(recruiter => {
        recruiterMap[recruiter.id] = {
          firstName: recruiter.FirstName,
          lastName: recruiter.LastName,
          color: recruiter.Color || '#006834',
        };
      });

      setRecruiters(recruitersData.map(recruiter => ({
        value: recruiter.id,
        label: `${recruiter.FirstName} ${recruiter.LastName}`,
      })));

      const formattedEvents = visitsData.map(visit => {
        const recruiter = recruiterMap[visit.RecruiterID] || { color: '#ccc' };
        const startDateTime = new Date(`${visit.StartDate}T${visit.Start_Time}`);
        const endDateTime = visit.EndDate
          ? new Date(`${visit.EndDate}T${visit.End_Time}`)
          : new Date(`${visit.StartDate}T${visit.End_Time}`);

        return {
          id: visit.id,
          title: `${recruiter.firstName} - ${visit.Event_Name}`,
          start: startDateTime,
          end: endDateTime,
          location: visit.Location,
          recruiter: recruiter,
          allDay: false,
          CEEB_Code: visit.CEEB_Code,
          Notes: visit.Notes,
          Address1: visit.Address1,
          Address2: visit.Address2,
          City: visit.City,
          State: visit.State,
          Host_FullName: visit.Host_FullName,
          Host_Email: visit.Host_Email,
          Host_Phone: visit.Host_Phone,
          Zipcode: visit.Zipcode,
          Handouts: visit.Handouts,
          Event_Name: visit.Event_Name,
        };
      });

      setEvents(formattedEvents);
      setFilteredEvents(formattedEvents);
    };

    fetchVisits();
  }, []);

  useEffect(() => {
    if (selectedRecruiter) {
      setFilteredEvents(events.filter(event => event.recruiter.firstName + ' ' + event.recruiter.lastName === selectedRecruiter.label));
    } else {
      setFilteredEvents(events);
    }
  }, [selectedRecruiter, events]);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };

  const handleUpdateEvent = (updatedEvent) => {
    const eventIndex = events.findIndex(e => e.id === updatedEvent.id);
    if (eventIndex !== -1) {
      const newEvents = [...events];
      newEvents[eventIndex] = updatedEvent;
      setEvents(newEvents);
      setFilteredEvents(newEvents);
    }
  };

  const handleRecruiterChange = (selectedOption) => {
    setSelectedRecruiter(selectedOption);
  };

  return (
    <div className="container-fluid">
      <div className="row"> 
        <div className="col-md-2"> 
          <div className="form-group" style={{ position: 'relative', zIndex: 10 }}> {/* Important: Add position and z-index to form-group */}
            <Select
              options={recruiters}
              value={selectedRecruiter}
              onChange={handleRecruiterChange}
              placeholder="Filter by Recruiter"
              isClearable
              styles={customStyles} 
            />
          </div>
        </div>
      </div>
      <div className="row"> 
        <div className="col-md-12"> 
          <Calendar
            localizer={localizer}
            events={filteredEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 800 }}
            step={15} 
            timeslots={4} 
            dayLayoutAlgorithm={'no-overlap'}
            min={new Date(2024, 8, 31, 6, 0)} 
            max={new Date(2024, 8, 31, 20, 0)} 
            eventPropGetter={(event) => ({
              style: { 
                backgroundColor: event.recruiter.color,
                borderRadius: '4px',
                border: `1px solid ${event.recruiter.color}`, 
                opacity: 0.8,
                // color: getTextColor(event.recruiter.color), // No longer needed
                padding: '1px 4px',
                zIndex: event === selectedEvent ? 10 : 1, 
                fontSize: '15px',
                height: '25px',
                lineHeight: '20px',
                margin: '1px 0',
              },
              className: 'sb-admin-event',
            })}
            popup
            onSelectEvent={handleSelectEvent}
          />
        </div>
      </div>

      {showModal && (
        <EventDetailsModal
          event={selectedEvent}
          onClose={handleCloseModal}
          onUpdateEvent={handleUpdateEvent}
        />
      )}
    </div>
  );
}


export default CalendarPage;