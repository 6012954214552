// src/components/NavBar.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import { FaCog } from 'react-icons/fa'; // Font Awesome icon for the gear

const NavBar = ({ session }) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/'); // Redirect to home after logout
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Extract user's name from session metadata
  const userName = session?.user?.user_metadata?.name || session?.user?.email || 'User';

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top"> 
      <Link className="navbar-brand" to="/">
        <img src="/logo.png" alt="Logo" style={{ height: '60px' }} /> 
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav ml-auto">
          {session ? (
            <>
              <li className="nav-item">
                <span className="navbar-text">
                  Welcome, {userName}
                </span>
              </li>
              <li className="nav-item">
                <button className="btn btn-link" onClick={handleLogout}>
                  Logout
                </button>
              </li>
              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  style={{ cursor: 'pointer' }}
                  onClick={toggleDropdown}
                >
                  <FaCog /> Settings
                </span>
                {isDropdownOpen && (
                  <div className="dropdown-menu dropdown-menu-right show">
                    <Link className="dropdown-item" to="/user-management">
                      User Account Management
                    </Link>
                    <Link className="dropdown-item" to="/recruiter-maintenance">
                      Recruiter Maintenance
                    </Link> 
                    <Link className="dropdown-item" to="/add-school">
                      Add School
                    </Link>
                    <Link className="dropdown-item" to="/add-counselor">
                      Add High School Counselor
                    </Link>
                    <Link className="dropdown-item" to="/applicants-upload">
                      Applicants Upload
                    </Link>
                  </div>
                )}
              </li>
            </>
          ) : (
            <li className="nav-item">
              <Link className="nav-link" to="/login">
                Login
              </Link>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;