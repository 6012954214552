// src/pages/AddCounselor.js
import React from 'react';

const AddCounselor = () => {
  return (
    <div>
      <h1>Add New High School Counselor</h1>
      {/* Add the form for adding a new counselor */}
    </div>
  );
};

export default AddCounselor;
