// src/components/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaTachometerAlt, FaSchool,  FaMapMarkedAlt, FaClipboardList, FaCalendarAlt } from 'react-icons/fa';

const Sidebar = () => {
  return (
    <div>
      <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

        {/* Sidebar - Brand */}
        <Link to="/" className="sidebar-brand d-flex align-items-center justify-content-center">
          {/* Your Brand Logo or Name can go here */}
        </Link>

        {/* Divider */}
        <hr className="sidebar-divider my-0" />

        {/* Nav Item - Dashboard */}
        <li className="nav-item">
          <Link to="/dashboard" className="nav-link">
            <FaTachometerAlt />
            <span>Dashboard</span>
          </Link>
        </li>

        {/* Divider */}
        <hr className="sidebar-divider" />

        {/* Nav Item - Calendar */}
        <li className="nav-item">
          <Link to="/calendar" className="nav-link">
            <FaCalendarAlt />
            <span>Calendar</span>
          </Link>
        </li>

        {/* Nav Item - Visits */}
        <li className="nav-item">
          <Link to="/visits" className="nav-link">
            <FaClipboardList />
            <span>Visits</span>
          </Link>
        </li>

        {/* Nav Item - Schools */}
        <li className="nav-item">
          <Link to="/schools" className="nav-link">
            <FaSchool />
            <span>Schools</span>
          </Link>
        </li>

        {/* Nav Item - Territories */}
        <li className="nav-item">
          <Link to="/territories" className="nav-link">
            <FaMapMarkedAlt />
            <span>Territories</span>
          </Link>
        </li>

        {/* Divider */}
        <hr className="sidebar-divider d-none d-md-block" />
      </ul>
    </div>
  );
};

export default Sidebar;