import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';

function UserManagement() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) {
        console.error('Error fetching user data:', error.message);
        setMessage('Error fetching user data');
      } else if (user && user.user_metadata) {
        setName(user.user_metadata.name || '');
        setEmail(user.user_metadata.email || '');
        setPhone(user.user_metadata.phone || '');
      }
    };

    fetchUserData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { user, error } = await supabase.auth.updateUser({
      data: { name, email, phone }
    });

    if (error) {
      setMessage('Error updating user data: ' + error.message);
    } else {
      setMessage('User data updated successfully!');
      console.log('Updated user:', user);
    }

    setLoading(false);
  };

  return (
    <div className="container mt-5">
      <h2>User Management</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Update Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Update Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Update Phone</label>
          <input
            type="text"
            className="form-control"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? 'Updating...' : 'Update'}
        </button>
        {message && <p className="mt-3">{message}</p>}
      </form>
    </div>
  );
}

export default UserManagement;
