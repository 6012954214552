// src/pages/RecruiterMaintenance.js

import React, { useState, useEffect } from 'react';
import { supabase } from '../utils/supabaseClient';
import { Modal, Button, Form } from 'react-bootstrap';

const RecruiterMaintenance = () => {
  const [recruiters, setRecruiters] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);

  // Form states
  const [id, setId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [color, setColor] = useState('#006834');

  useEffect(() => {
    fetchRecruiters();
  }, []);

  const fetchRecruiters = async () => {
    const { data, error } = await supabase.from('Recruiters').select('*');
    if (error) {
      console.error('Error fetching recruiters:', error);
    } else {
      setRecruiters(data);
    }
  };

  const handleShowModal = () => {
    setEditMode(false);
    clearForm();
    setShowModal(true);
  };

  const handleEdit = (recruiter) => {
    setEditMode(true);
    setId(recruiter.id);
    setFirstName(recruiter.FirstName);
    setLastName(recruiter.LastName);
    setPhoneNumber(recruiter.Phone_Number);
    setTitle(recruiter.Title);
    setEmail(recruiter.Email);
    setColor(recruiter.Color || '#006834');
    setShowModal(true);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (editMode) {
      // Update recruiter
      const { error } = await supabase
        .from('Recruiters')
        .update({
          FirstName: firstName,
          LastName: lastName,
          Phone_Number: phoneNumber,
          Title: title,
          Email: email,
          Color: color,
        })
        .eq('id', id);

      if (error) {
        console.error('Error updating recruiter:', error);
      }
    } else {
      // Add new recruiter
      const { error } = await supabase.from('Recruiters').insert([
        {
          id: id,
          FirstName: firstName,
          LastName: lastName,
          Phone_Number: phoneNumber,
          Title: title,
          Email: email,
          Color: color,
        },
      ]);

      if (error) {
        console.error('Error adding recruiter:', error);
      }
    }

    fetchRecruiters();
    setShowModal(false);
  };

  const clearForm = () => {
    setId('');
    setFirstName('');
    setLastName('');
    setPhoneNumber('');
    setTitle('');
    setEmail('');
    setColor('#006834');
  };

  return (
    <div style={{ margin: '20px' }}>
      <h1>Recruiter Maintenance</h1>
      <Button variant="primary" onClick={handleShowModal}>
        Add New Recruiter
      </Button>
      <table className="table table-striped" style={{ marginTop: '20px', width: '100%' }}>
        <thead>
          <tr>
            <th>Banner ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Phone Number</th>
            <th>Title</th>
            <th>Color</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {recruiters.map((recruiter) => (
            <tr key={recruiter.id}>
              <td>{recruiter.id}</td>
              <td>{recruiter.FirstName}</td>
              <td>{recruiter.LastName}</td>
              <td>{recruiter.Phone_Number}</td>
              <td>{recruiter.Title}</td>
              <td>
                <span style={{ backgroundColor: recruiter.Color, padding: '5px', color: '#fff' }}>
                  {recruiter.Color}
                </span>
              </td>
              <td>{recruiter.Email}</td>
              <td>
                <Button variant="warning" onClick={() => handleEdit(recruiter)}>
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for Adding/Editing Recruiter */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? 'Edit Recruiter' : 'Add New Recruiter'}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSave}>
          <Modal.Body>
            <Form.Group controlId="formId">
              <Form.Label>Banner ID</Form.Label>
              <Form.Control
                type="text"
                value={id}
                onChange={(e) => setId(e.target.value)}
                disabled={editMode}
                required
              />
            </Form.Group>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formColor">
              <Form.Label>Color</Form.Label>
              <Form.Control
                type="color"
                value={color}
                onChange={(e) => setColor(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default RecruiterMaintenance;
