import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ session }) => {
  // If session is still being fetched (null) return a loading spinner or some indicator
  if (session === undefined) {
    return <div>Loading...</div>;
  }

  return session ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;