import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabaseClient';
import Select from 'react-select'; // Import react-select for dropdowns

const AddSchool = () => {
  const navigate = useNavigate();

  // State for form fields
  const [ceebCode, setCeebCode] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [schoolPhone, setSchoolPhone] = useState('');
  const [county, setCounty] = useState('');
  const [district, setDistrict] = useState('');
  const [schoolType, setSchoolType] = useState('');

  const [states, setStates] = useState([]);
  const [filteredCounties, setFilteredCounties] = useState([]);
  const [schoolExists, setSchoolExists] = useState(false);

  // Fetch available states and counties
  useEffect(() => {
    const fetchStatesAndCounties = async () => {
      try {
        // Fetch states and counties
        const { data, error } = await supabase
          .from('County')
          .select('State, County');

        if (error) {
          console.error('Error fetching states and counties:', error);
          return;
        }

        // Extract unique states and counties
        const uniqueStates = Array.from(new Set(data.map(item => item.State)))
          .sort()
          .map(state => ({
            value: state,
            label: state,
          }));

        // Set states
        setStates(uniqueStates);

        // Initialize counties
        const allCounties = data.map(item => ({
          value: item.County,
          label: item.County,
          state: item.State,
        }));

        // Function to filter counties based on selected state
        const filterCounties = (selectedState) => {
          const filtered = allCounties
            .filter(county => county.state === selectedState)
            .sort((a, b) => a.label.localeCompare(b.label)); // Sort counties A-Z
          setFilteredCounties(filtered);
        };

        // Filter counties when state changes
        filterCounties(state);

      } catch (error) {
        console.error('Error fetching states and counties:', error);
      }
    };

    fetchStatesAndCounties();
  }, [state]);

  const handlePhoneNumberChange = (e) => {
    const rawValue = e.target.value;
    // Remove all non-numeric characters
    const numericValue = rawValue.replace(/\D/g, '');
    // Format the phone number as (XXX) XXX-XXXX
    const formattedValue = numericValue
      .replace(/(\d{3})(\d)/, '($1) $2')
      .replace(/(\d{3})(\d)/, '$1-$2');
    setSchoolPhone(formattedValue);
  };

  const checkCeebCode = async (code) => {
    const { data, error } = await supabase
      .from('HighSchools')
      .select('CEEB_Code')
      .eq('CEEB_Code', code)
      .single();

    if (error && error.code !== 'PGRST100') {
      console.error('Error checking CEEB_Code:', error.message);
    }

    if (data) {
      setSchoolExists(true);
      alert('A school with this CEEB Code already exists.');
    } else {
      setSchoolExists(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (schoolExists) {
      alert('Please enter a unique CEEB Code.');
      return;
    }

    if (!schoolName || !state || !city || !zip || !ceebCode) {
      alert('Please fill in all required fields.');
      return;
    }

    const { error } = await supabase.from('HighSchools').insert([
      {
        CEEB_Code: ceebCode,
        School_Name: schoolName,
        Address: address,
        City: city,
        State: state,
        Zip: zip,
        School_Phone: schoolPhone || null,
        County: county,
        District: district,
        School_Type: schoolType,
      },
    ]);

    if (error) {
      console.error('Error adding school:', error.message);
      alert('There was an error adding the school.');
    } else {
      alert('School added successfully!');
      navigate('/schools');
    }
  };

  // Custom styles for react-select to match the input fields
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: '#ddd',
      borderRadius: '50px',
      padding: '6px 12px',
      minHeight: '40px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#006834',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#ddd',
      '&:hover': {
        color: '#006834',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#999',
    }),
  };

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">Add New School</h1>
      <form className="user" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>CEEB Code</label>
          <input
            type="text"
            className="form-control form-control-user"
            value={ceebCode}
            onChange={(e) => {
              setCeebCode(e.target.value);
              if (e.target.value) {
                checkCeebCode(e.target.value);
              }
            }}
            required
          />
        </div>
        <div className="form-group">
          <label>School Name</label>
          <input
            type="text"
            className="form-control form-control-user"
            value={schoolName}
            onChange={(e) => setSchoolName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Address</label>
          <input
            type="text"
            className="form-control form-control-user"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>City</label>
          <input
            type="text"
            className="form-control form-control-user"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>State</label>
          <Select
            options={states}
            value={states.find(stateOption => stateOption.value === state)}
            onChange={(option) => {
              setState(option ? option.value : '');
              setCounty(''); // Reset county when state changes
            }}
            placeholder="Select State"
            isClearable
            styles={customStyles} // Apply custom styles
            required
          />
        </div>
        <div className="form-group">
          <label>County</label>
          <Select
            options={filteredCounties}
            value={filteredCounties.find(countyOption => countyOption.value === county)}
            onChange={(option) => setCounty(option ? option.value : '')}
            placeholder="Select County"
            isClearable
            styles={customStyles} // Apply custom styles
          />
        </div>
        <div className="form-group">
          <label>Zip Code</label>
          <input
            type="text"
            className="form-control form-control-user"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>School Phone</label>
          <input
            type="text"
            className="form-control form-control-user"
            value={schoolPhone}
            onChange={handlePhoneNumberChange}
          />
        </div>
        <div className="form-group">
          <label>District</label>
          <input
            type="text"
            className="form-control form-control-user"
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>School Type</label>
          <input
            type="text"
            className="form-control form-control-user"
            value={schoolType}
            onChange={(e) => setSchoolType(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary btn-user btn-block">
          Add School
        </button>
      </form>
    </div>
  );
};

export default AddSchool;
