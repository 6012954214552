/* src/pages/Territories.js */

import React, { useEffect, useReducer, useState, useMemo } from 'react';
import { supabase } from '../utils/supabaseClient';

// Reducer to manage state
const initialState = {
  territories: [],
  recruiters: [],
  loading: true,
  error: null,
  editMode: null, // Keeps track of the row being edited
  selectedRows: [], // Tracks selected rows
};

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_SUCCESS':
      return {
        ...state,
        territories: action.payload.territories,
        recruiters: action.payload.recruiters,
        loading: false,
      };
    case 'FETCH_ERROR':
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case 'SET_EDIT_MODE':
      return {
        ...state,
        editMode: action.payload,
      };
    case 'UPDATE_TERRITORY':
      return {
        ...state,
        territories: state.territories.map((territory) =>
          territory.id === action.payload.id ? action.payload : territory
        ),
        editMode: null,
      };
    case 'TOGGLE_ROW_SELECTION':
      return {
        ...state,
        selectedRows: state.selectedRows.includes(action.payload)
          ? state.selectedRows.filter((id) => id !== action.payload)
          : [...state.selectedRows, action.payload],
      };
    case 'CLEAR_SELECTION':
      return {
        ...state,
        selectedRows: [],
      };
    default:
      return state;
  }
}

function Territories() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { territories, recruiters, loading, error, editMode, selectedRows } = state;
  const [editedTerritory, setEditedTerritory] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'State', direction: 'asc' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [countiesRes, recruitersRes] = await Promise.all([
          supabase.from('County').select('State, County, Priority, RecruiterID'),
          supabase.from('Recruiters').select('id, FirstName, LastName'),
        ]);

        if (countiesRes.error || recruitersRes.error) {
          throw new Error('Failed to fetch data');
        }

        const territories = countiesRes.data.map((county) => {
          const recruiter = recruitersRes.data.find(
            (recruiter) => recruiter.id === county.RecruiterID
          );
          return {
            ...county,
            id: `${county.State}-${county.County}`,
            RecruiterName: recruiter ? `${recruiter.FirstName} ${recruiter.LastName}` : 'Unassigned',
          };
        });

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: { territories, recruiters: recruitersRes.data },
        });
      } catch (error) {
        dispatch({ type: 'FETCH_ERROR', payload: 'Error fetching data. Please try again later.' });
      }
    };

    fetchData();
  }, []);

  const handleEditClick = (territory) => {
    dispatch({ type: 'SET_EDIT_MODE', payload: territory.id });
    setEditedTerritory({ ...territory });
  };

  const handleSaveClick = async () => {
    try {
      const { error } = await supabase
        .from('County')
        .update({
          RecruiterID: editedTerritory.RecruiterID,
          Priority: editedTerritory.Priority,
        })
        .match({ State: editedTerritory.State, County: editedTerritory.County });

      if (error) {
        throw new Error('Failed to update territory');
      }

      dispatch({
        type: 'UPDATE_TERRITORY',
        payload: editedTerritory,
      });
    } catch (error) {
      console.error('Error updating territory:', error.message);
    }
  };

  const handleCancelClick = () => {
    dispatch({ type: 'SET_EDIT_MODE', payload: null });
  };

  const handleInputChange = (e) => {
    setEditedTerritory({
      ...editedTerritory,
      [e.target.name]: e.target.value,
    });
  };

  const handleRowSelection = (id) => {
    dispatch({ type: 'TOGGLE_ROW_SELECTION', payload: id });
  };

  const handleUpdateAllChecked = async () => {
    try {
      const updates = selectedRows.map((id) => {
        const territory = territories.find((t) => t.id === id);
        return {
          State: territory.State,
          County: territory.County,
          RecruiterID: editedTerritory.RecruiterID || territory.RecruiterID,
          Priority: editedTerritory.Priority || territory.Priority,
        };
      });

      const { error } = await supabase.from('County').upsert(updates);

      if (error) {
        throw new Error('Failed to update selected territories');
      }

      dispatch({ type: 'CLEAR_SELECTION' });
      setEditedTerritory({});
      // Refresh the data after update
      const updatedTerritories = territories.map((territory) => {
        if (selectedRows.includes(territory.id)) {
          return {
            ...territory,
            RecruiterID: editedTerritory.RecruiterID || territory.RecruiterID,
            Priority: editedTerritory.Priority || territory.Priority,
            RecruiterName:
              recruiters.find((r) => r.id === (editedTerritory.RecruiterID || territory.RecruiterID))
                ?.FirstName +
              ' ' +
              recruiters.find((r) => r.id === (editedTerritory.RecruiterID || territory.RecruiterID))
                ?.LastName,
          };
        }
        return territory;
      });

      dispatch({ type: 'FETCH_SUCCESS', payload: { territories: updatedTerritories, recruiters } });
    } catch (error) {
      console.error('Error updating selected territories:', error.message);
    }
  };

  const filteredTerritories = useMemo(() => {
    let filtered = territories;

    if (searchTerm) {
      filtered = filtered.filter((territory) => {
        return (
          territory.State.toLowerCase().includes(searchTerm.toLowerCase()) ||
          territory.County.toLowerCase().includes(searchTerm.toLowerCase()) ||
          territory.RecruiterName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          territory.Priority.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    }

    // Sort based on sortConfig
    if (sortConfig) {
      filtered.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (aValue === null || aValue === undefined) aValue = ''; // Handle null/undefined
        if (bValue === null || bValue === undefined) bValue = ''; // Handle null/undefined

        if (sortConfig.key === 'RecruiterName' || sortConfig.key === 'Priority') {
          aValue = aValue.toLowerCase();
          bValue = bValue.toLowerCase();
        }

        if (sortConfig.direction === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });
    }

    return filtered;
  }, [territories, searchTerm, sortConfig]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container-fluid">
      <h1 className="h3 mb-4 text-gray-800">Territories</h1>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">Territory Management</h6>
        </div>
        <div className="card-body">
          <div className="form-row mb-3">
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
                <tr>
                  <th></th>
                  <th onClick={() => requestSort('State')} style={{ cursor: 'pointer' }}>
                    State
                  </th>
                  <th onClick={() => requestSort('County')} style={{ cursor: 'pointer' }}>
                    County
                  </th>
                  <th onClick={() => requestSort('RecruiterName')} style={{ cursor: 'pointer' }}>
                    Recruiter
                  </th>
                  <th onClick={() => requestSort('Priority')} style={{ cursor: 'pointer' }}>
                    Priority
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredTerritories.map((territory) => (
                  <tr key={territory.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(territory.id)}
                        onChange={() => handleRowSelection(territory.id)}
                      />
                    </td>
                    <td>{territory.State}</td>
                    <td>{territory.County}</td>
                    <td>
                      {editMode === territory.id ? (
                        <select
                          name="RecruiterID"
                          value={editedTerritory.RecruiterID}
                          onChange={handleInputChange}
                          className="form-control"
                        >
                          <option value="">Unassigned</option>
                          {recruiters.map((recruiter) => (
                            <option key={recruiter.id} value={recruiter.id}>
                              {recruiter.FirstName} {recruiter.LastName}
                            </option>
                          ))}
                        </select>
                      ) : (
                        territory.RecruiterName
                      )}
                    </td>
                    <td>
                      {editMode === territory.id ? (
                        <select
                          name="Priority"
                          value={editedTerritory.Priority}
                          onChange={handleInputChange}
                          className="form-control"
                        >
                          <option value="Primary">Primary</option>
                          <option value="Secondary">Secondary</option>
                          <option value="Tertiary">Tertiary</option>
                        </select>
                      ) : (
                        territory.Priority
                      )}
                    </td>
                    <td>
                      {editMode === territory.id ? (
                        <>
                          <button className="btn btn-success btn-sm" onClick={handleSaveClick}>
                            Save
                          </button>
                          <button className="btn btn-secondary btn-sm" onClick={handleCancelClick}>
                            Cancel
                          </button>
                        </>
                      ) : (
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => handleEditClick(territory)}
                        >
                          Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {selectedRows.length > 0 && (
        <div className="card shadow mb-4">
          <div className="card-header py-3">
            <h6 className="m-0 font-weight-bold text-primary">Update All Checked</h6>
          </div>
          <div className="card-body">
            <label className="form-group">
              Recruiter:
              <select
                name="RecruiterID"
                value={editedTerritory.RecruiterID || ''}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">Unassigned</option>
                {recruiters.map((recruiter) => (
                  <option key={recruiter.id} value={recruiter.id}>
                    {recruiter.FirstName} {recruiter.LastName}
                  </option>
                ))}
              </select>
            </label>
            <label className="form-group">
              Priority:
              <select
                name="Priority"
                value={editedTerritory.Priority || ''}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="Primary">Primary</option>
                <option value="Secondary">Secondary</option>
                <option value="Tertiary">Tertiary</option>
              </select>
            </label>
            <button
              className="btn btn-success btn-sm mt-3"
              onClick={handleUpdateAllChecked}
            >
              Update All Checked
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Territories;
