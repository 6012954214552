// File: src/pages/CounselorsList.js

import React from 'react';

function CounselorsList({ counselors, counselorError, counselorLoading }) {
  if (counselorLoading) return <div>Loading counselors...</div>;
  if (counselorError) return <div>Error: {counselorError}</div>;

  if (counselors.length === 0) return null;

  return (
    <div>
      <h3>Counselors</h3>
      <ul>
        {counselors.map((counselor) => (
          <li key={counselor.Counselor_Email}>
            {counselor.FirstName} {counselor.LastName} ({counselor.Counselor_Email})
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CounselorsList;
