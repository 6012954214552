// src/pages/Recruits.js
import React from 'react';

function Recruits() {
  return (
    <div>
      <h1>Recruits Page</h1>
      <p>This is where you'll manage recruits.</p>
    </div>
  );
}

export default Recruits;
