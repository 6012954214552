import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import '../styles/App.css';
import { supabase } from '../utils/supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

function EventDetailsModal({ event, onClose, onUpdateEvent }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editableEvent, setEditableEvent] = useState(null);
  const [recruiters, setRecruiters] = useState([]); // This state will hold all recruiters
  const [selectedRecruiter, setSelectedRecruiter] = useState(null); // This state will hold the selected recruiter
  const [schools, setSchools] = useState([]); // Reintroduced schools state
  const [files, setFiles] = useState([]);
  const [uploadedFileUrls, setUploadedFileUrls] = useState([]);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  useEffect(() => {
    if (event) {
      setEditableEvent({
        ...event,
        StartDate: event.start ? event.start.toISOString().split('T')[0] : '',
        EndDate: event.end ? event.end.toISOString().split('T')[0] : '',
        Start_Time: event.start ? event.start.toTimeString().split(' ')[0] : '',
        End_Time: event.end ? event.end.toTimeString().split(' ')[0] : '',
        Address1: event.Address1 || '',
        Address2: event.Address2 || '',
        City: event.City || '',
        State: event.State || '',
        Zipcode: event.Zipcode || '',
        Visit_Type: event.Visit_Type || '',
        Notes: event.Notes || '',
        Host_FullName: event.Host_FullName || '',
        Host_Email: event.Host_Email || '',
        Host_Phone: event.Host_Phone || '',
        Handouts: event.Handouts || '',
        Event_Name: event.Event_Name || '',
        location: event.location || '',
        RecruiterID: event.RecruiterID || '',
        CEEB_Code: event.CEEB_Code || null,
        FileAttachments: event.FileAttachments || [],
        recruiter: event.recruiter || { firstName: '', lastName: '' },
      });

      // Fetch ALL recruiters for the dropdown
      const fetchRecruiters = async () => {
        const { data: recruitersData, error } = await supabase
          .from('Recruiters')
          .select('id, FirstName, LastName');

        if (error) {
          console.error('Error fetching recruiters:', error);
          return;
        }

        setRecruiters(recruitersData.map((recruiter) => ({
          value: recruiter.id,
          label: `${recruiter.FirstName} ${recruiter.LastName}`,
        })));
      };

      // Fetch the specific recruiter for pre-selection
      const fetchRecruiter = async () => {
        if (event.RecruiterID) {
          const { data: recruiterData, error } = await supabase
            .from('Recruiters')
            .select('id, FirstName, LastName')
            .eq('id', event.RecruiterID)
            .single();

          if (error) {
            console.error('Error fetching recruiter:', error);
            return;
          }

          // Set the selected recruiter
          setSelectedRecruiter({
            value: recruiterData.id,
            label: `${recruiterData.FirstName} ${recruiterData.LastName}`,
          });
        }
      };

      // Fetch schools
      const fetchSchools = async () => {
        const { data: schoolsData, error } = await supabase
          .from('HighSchools')
          .select('CEEB_Code, School_Name');

        if (error) {
          console.error('Error fetching schools:', error);
          return;
        }

        setSchools(schoolsData.map(school => ({
          value: school.CEEB_Code,
          label: school.School_Name,
        })));
      };

      // ... (rest of your useEffect code) ...

      fetchRecruiters(); // Fetch all recruiters
      fetchRecruiter(); // Fetch the specific recruiter
      fetchSchools(); // Fetch schools
    }

    // ... (rest of your useEffect code) ...
  }, [event]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    if (editableEvent) {
      try {
        const { data: sessionData, error: sessionError } = await supabase.auth.getSession();

        if (sessionError || !sessionData || !sessionData.session) {
          console.error('Error retrieving session or no session found:', sessionError);
          alert('Session not found. Please log in again.');
          return;
        }

        // Convert empty strings to null for fields expecting numbers
        const sanitizedEvent = {
          ...editableEvent,
          RecruiterID: editableEvent.RecruiterID === '' ? null : editableEvent.RecruiterID,
          CEEB_Code: editableEvent.CEEB_Code === '' ? null : editableEvent.CEEB_Code,
          FileAttachments: editableEvent.FileAttachments.filter(Boolean) // Sanitize FileAttachments
        };

        // Handle multiple file uploads
        const uploadedFileNames = [...sanitizedEvent.FileAttachments]; // Start with existing files
        for (const file of files) {
          const fileName = `${editableEvent.id}-${file.name}`;

          const { error: uploadError } = await supabase.storage
            .from('visit-files')
            .upload(fileName, file, {
              upsert: true,
            });

          if (uploadError) {
            console.error('Error uploading file:', uploadError);
            alert(`File upload failed for ${file.name}. Please try again.`);
          } else {
            console.log(`File uploaded successfully: ${fileName}`);
            uploadedFileNames.push(fileName);
          }
        }

        sanitizedEvent.FileAttachments = uploadedFileNames;

        // Update event details in the database
        const { error: updateError } = await supabase
          .from('Visits')
          .update({
            StartDate: sanitizedEvent.StartDate,
            Start_Time: sanitizedEvent.Start_Time,
            EndDate: sanitizedEvent.EndDate,
            End_Time: sanitizedEvent.End_Time,
            Location: sanitizedEvent.location,
            Address1: sanitizedEvent.Address1,
            Address2: sanitizedEvent.Address2,
            City: sanitizedEvent.City,
            State: sanitizedEvent.State,
            Zipcode: sanitizedEvent.Zipcode,
            Visit_Type: sanitizedEvent.Visit_Type,
            Notes: sanitizedEvent.Notes,
            Host_FullName: sanitizedEvent.Host_FullName,
            Host_Email: sanitizedEvent.Host_Email,
            Host_Phone: sanitizedEvent.Host_Phone,
            Handouts: sanitizedEvent.Handouts,
            Event_Name: sanitizedEvent.Event_Name,
            RecruiterID: sanitizedEvent.RecruiterID,
            CEEB_Code: sanitizedEvent.CEEB_Code || null,
            FileAttachments: sanitizedEvent.FileAttachments, // Update FileAttachments
          })
          .eq('id', sanitizedEvent.id);

        if (updateError) {
          console.error('Error updating event:', updateError);
          alert('Error updating event. Please try again.');
          return; // Stop execution if update fails
        }

        console.log('Event updated successfully');

        onUpdateEvent({
          ...sanitizedEvent,
          start: new Date(`${sanitizedEvent.StartDate}T${sanitizedEvent.Start_Time}`),
          end: new Date(`${sanitizedEvent.EndDate}T${sanitizedEvent.End_Time}`)
        });
      } catch (err) {
        console.error('Error during save process:', err);
        alert('An error occurred while saving. Please try again.'); 
      }
    }
    setIsEditing(false);
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableEvent((prevEvent) => ({
      ...prevEvent,
      [name]: value,
      FileAttachments: prevEvent.FileAttachments // Include FileAttachments here
    }));
  };

  const handleRecruiterChange = (selectedOption) => {
    setSelectedRecruiter(selectedOption); // Update the selectedRecruiter state
    setEditableEvent((prevEvent) => ({
      ...prevEvent,
      RecruiterID: selectedOption ? selectedOption.value : '',
      recruiter: {
        firstName: selectedOption ? selectedOption.label.split(' ')[0] : '',
        lastName: selectedOption ? selectedOption.label.split(' ')[1] : '',
      },
    }));
  };

  const handleSchoolChange = (selectedOption) => {
    setEditableEvent((prevEvent) => ({
      ...prevEvent,
      CEEB_Code: selectedOption ? selectedOption.value : '',
    }));
  };

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const handleDeleteFile = async (fileName) => {
    setFileToDelete(fileName);
    setIsDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (fileToDelete) {
      try {
        const { error: deleteError } = await supabase
          .storage
          .from('visit-files')
          .remove([fileToDelete]);

        if (deleteError) {
          console.error('Error deleting file:', deleteError);
          alert(`Failed to delete file ${fileToDelete}. Please try again.`);
          return;
        }

        // Update the state to remove the deleted file
        const updatedFileAttachments = editableEvent.FileAttachments.filter(
          (existingFileName) => existingFileName !== fileToDelete
        );

        setEditableEvent((prevEvent) => ({
          ...prevEvent,
          FileAttachments: updatedFileAttachments,
        }));

        // Update the uploadedFileUrls state to reflect the deleted file
        setUploadedFileUrls((prevUrls) => prevUrls.filter((url) => url.name !== fileToDelete));

        setIsDeleteConfirmOpen(false); // Close the popup
        setFileToDelete(null);
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    }
  };

  const handleCancelDelete = () => {
    setIsDeleteConfirmOpen(false);
    setFileToDelete(null);
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: '#d1d3e2',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#858796',
      },
      borderRadius: '0.35rem',
      minHeight: 'calc(1.5em + 0.75rem + 2px)',
      height: 'calc(1.5em + 0.75rem + 2px)',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: 'calc(1.5em + 0.75rem + 2px)',
      padding: '0 0.75rem',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: 'calc(1.5em + 0.75rem + 2px)',
    }),
  };

  const handleExportICS = () => {
    console.log("Exporting event:", event); // Log the event object for debugging
    if (!event) {
        console.error('No event to export.');
        return;
    }

    const school = schools.find(s => s.value === event.CEEB_Code);
    const schoolName = school ? school.label : 'Unknown School';
    const fileAttachments = event.FileAttachments && Array.isArray(event.FileAttachments) ? event.FileAttachments.join(', ') : 'None';
    const recruiter = event.recruiter ? `${event.recruiter.firstName} ${event.recruiter.lastName}` : 'Unknown Recruiter';

    let icsString = `BEGIN:VCALENDAR\nVERSION:2.0\nCALSCALE:GREGORIAN\nPRODID:-//Supabase Calendar//EN\n`;

    icsString += `BEGIN:VEVENT\n`;
    icsString += `UID:${event.id}\n`;
    icsString += `SUMMARY:${event.Event_Name || 'No Title'}\n`;
    icsString += `DTSTART:${new Date(event.start).toISOString().replace(/[-:]/g, '').split('.')[0]}\n`;
    icsString += `DTEND:${new Date(event.end).toISOString().replace(/[-:]/g, '').split('.')[0]}\n`;
    icsString += `LOCATION:${event.Address1 || ''} ${event.Address2 ? event.Address2 + ' ' : ''}${event.City || ''} ${event.State || ''} ${event.Zipcode || ''}\n`;

    let description = `School: ${schoolName}\n`;
    description += `Recruiter: ${recruiter}\n`;
    description += `Visit Type: ${event.Visit_Type || 'No Visit Type'}\n`;
    description += `Notes: ${event.Notes || 'No Notes'}\n`;
    description += `Host: ${event.Host_FullName || 'No Host Name'}\n`;
    description += `Host Email: ${event.Host_Email || 'No Host Email'}\n`;
    description += `Host Phone: ${event.Host_Phone || 'No Host Phone'}\n`;
    description += `Handouts: ${event.Handouts || 'None'}\n`;
    description += `File Attachments: ${fileAttachments}\n`;

    // Encode newlines for the ICS file
    icsString += `DESCRIPTION:${description.replace(/\n/g, '\\n')}\n`;
    icsString += `END:VEVENT\n`;
    icsString += `END:VCALENDAR`;

    const link = document.createElement('a');
    link.href = 'data:text/calendar;charset=utf-8,' + encodeURIComponent(icsString);
    link.download = 'event.ics';
    link.click();
  };


  if (!editableEvent) return null;

  return (
    <div className="modal fade show d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
          <div className="modal-header">
            <h5 className="modal-title">
              {!isEditing && editableEvent.Event_Name}
            </h5>
            <button type="button" className="close" aria-label="Close" onClick={onClose}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div><strong>Recruiter:</strong> {isEditing ? (
              recruiters.length > 0 && (
                <Select
                  name="RecruiterID"
                  value={selectedRecruiter} // Use selectedRecruiter here to pre-select
                  onChange={handleRecruiterChange}
                  options={recruiters}
                  styles={customSelectStyles}
                  className="form-control p-0"
                />
              )
            ) : (
              `${editableEvent.recruiter.firstName} ${editableEvent.recruiter.lastName}`
            )}</div>
            <div><strong>School:</strong> {isEditing ? (
              <Select
                name="CEEB_Code"
                value={schools.find(school => school.value === editableEvent.CEEB_Code)}
                onChange={handleSchoolChange}
                options={schools}
                styles={customSelectStyles}
                className="form-control p-0"
                isClearable
                isSearchable
              />
            ) : (
              editableEvent.CEEB_Code
            )}</div>
            <div><strong>Event Name:</strong> {isEditing ? (
              <input
                type="text"
                name="Event_Name"
                value={editableEvent.Event_Name || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              editableEvent.Event_Name
            )}</div>
            <div><strong>Start Date:</strong> {isEditing ? (
              <input
                type="date"
                name="StartDate"
                value={editableEvent.StartDate || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              new Date(editableEvent.start).toLocaleDateString()
            )}</div>
            <div><strong>End Date:</strong> {isEditing ? (
              <input
                type="date"
                name="EndDate"
                value={editableEvent.EndDate || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              new Date(editableEvent.end).toLocaleDateString()
            )}</div>
            <div><strong>Start Time:</strong> {isEditing ? (
              <input
                type="time"
                name="Start_Time"
                value={editableEvent.Start_Time || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              new Date(`${editableEvent.StartDate}T${editableEvent.Start_Time}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            )}</div>
            <div><strong>End Time:</strong> {isEditing ? (
              <input
                type="time"
                name="End_Time"
                value={editableEvent.End_Time || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              new Date(`${editableEvent.EndDate}T${editableEvent.End_Time}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
            )}</div>
            <div><strong>Location:</strong> {isEditing ? (
              <input
                type="text"
                name="location"
                value={editableEvent.location || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              editableEvent.location
            )}</div>
            <div><strong>Address 1:</strong> {isEditing ? (
              <input
                type="text"
                name="Address1"
                value={editableEvent.Address1 || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              editableEvent.Address1
            )}</div>
            <div><strong>Address 2:</strong> {isEditing ? (
              <input
                type="text"
                name="Address2"
                value={editableEvent.Address2 || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              editableEvent.Address2
            )}</div>
            <div><strong>City:</strong> {isEditing ? (
              <input
                type="text"
                name="City"
                value={editableEvent.City || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              editableEvent.City
            )}</div>
            <div><strong>State:</strong> {isEditing ? (
              <input
                type="text"
                name="State"
                value={editableEvent.State || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              editableEvent.State
            )}</div>
            <div><strong>Zipcode:</strong> {isEditing ? (
              <input
                type="text"
                name="Zipcode"
                value={editableEvent.Zipcode || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              editableEvent.Zipcode
            )}</div>
            <div><strong>Visit Type:</strong> {isEditing ? (
              <input
                type="text"
                name="Visit_Type"
                value={editableEvent.Visit_Type || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              editableEvent.Visit_Type
            )}</div>
            <div><strong>Notes:</strong> {isEditing ? (
              <textarea
                name="Notes"
                value={editableEvent.Notes || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              editableEvent.Notes
            )}</div>
            <div><strong>Host Full Name:</strong> {isEditing ? (
              <input
                type="text"
                name="Host_FullName"
                value={editableEvent.Host_FullName || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              editableEvent.Host_FullName
            )}</div>
            <div><strong>Host Email:</strong> {isEditing ? (
              <input
                type="email"
                name="Host_Email"
                value={editableEvent.Host_Email || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              editableEvent.Host_Email
            )}</div>
            <div><strong>Host Phone:</strong> {isEditing ? (
              <input
                type="tel"
                name="Host_Phone"
                value={editableEvent.Host_Phone || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              editableEvent.Host_Phone
            )}</div>
            <div><strong>Handouts:</strong> {isEditing ? (
              <input
                type="text"
                name="Handouts"
                value={editableEvent.Handouts || ''}
                onChange={handleChange}
                className="form-control"
              />
            ) : (
              editableEvent.Handouts
            )}</div>
            <div><strong>File Attachments:</strong>
              {isEditing ? (
                <>
                  <input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    className="form-control"
                  />
                  <ul>
                    {uploadedFileUrls.map(({ name, url }) => (
                      <li key={name} className="d-flex align-items-center">
                        <a href={url} target="_blank" rel="noopener noreferrer">{name}</a>
                        <span className="ml-2" onClick={() => handleDeleteFile(name)}>
                          <FontAwesomeIcon icon={faTimesCircle} color="red" size="sm" />
                        </span>
                      </li>
                    ))}
                  </ul>

                  {/* Confirmation Popup */}
                  {isDeleteConfirmOpen && (
                    <div className="modal fade show d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Delete File Confirmation</h5>
                            <button type="button" className="close" aria-label="Close" onClick={handleCancelDelete}>
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            Are you sure you want to delete the file "{fileToDelete}"?
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>Delete</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                </>
              ) : (
                uploadedFileUrls.length > 0 ? (
                  <ul>
                    {uploadedFileUrls.map(({ name, url }) => (
                      <li key={name}>
                        <a href={url} target="_blank" rel="noopener noreferrer">{name}</a>
                      </li>
                    ))}
                  </ul>
                ) : (
                  'No files uploaded'
                )
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
            {isEditing ? (
              <button type="button" className="btn btn-primary" onClick={handleSaveClick}>Save</button>
            ) : (
              <>
                <button type="button" className="btn btn-primary" onClick={handleEditClick}>Edit</button>
                <button type="button" className="btn btn-success" onClick={handleExportICS}>Export ICS</button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventDetailsModal;